import { AplautConfig, WidgetBuilder } from "@widgets/aplaut/types";

export class Aplaut {
    static #instance: Promise<any>;

    private constructor(config: AplautConfig) {
        Aplaut.#instance = new Promise((resolve) => {
            const { storeId, theme = 'default', isDefaultStyles = true } = config;

            (window as any)._shoppilot = (window as any)._shoppilot || [];
            if (isDefaultStyles) {
                (window as any)._shoppilot.push(['_addStyles', 'widgets']);
            }

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = '//get.aplaut.io/f/v1/'
                + storeId + '/' + theme + '/app.js';
            const s = document.getElementsByTagName('script')[0];
            s.parentNode?.insertBefore(script, s);

            script.onload = function () {
                resolve(true);
            }
        });
    }

    public static create(config: AplautConfig) {
        if (!Aplaut.#instance) {
            new Aplaut(config);
            return Aplaut.#instance;
        }

        return Aplaut.#instance;
    }
}

export class ProductWidget {
    private WidgetBuilder: WidgetBuilder
    private waitRenderWidgets: any[] = [];
    private productId: string;

    constructor(productId: string) {

        if (!Shoppilot) {
            throw new Error('Aplaut is not loaded yet')
        }
        this.productId = productId;

        this.WidgetBuilder = Shoppilot.require("product_widget");
    }

    createInlineRating(container: string = '#sp-inline-rating-container') {
        const inlineRating = new this.WidgetBuilder({
            name: "inline-rating",
            product_id: this.productId,
            container,
        });

        this.waitRenderWidgets.push(inlineRating);

        return this;
    }

    createReviews(container: string = '#sp-reviews-container') {
        const reviews = new this.WidgetBuilder({
            name: "product-reviews",
            product_id: this.productId,
            container,
        });

        this.waitRenderWidgets.push(reviews);

        return this;
    }

    createListingRating(container: string) {
        const listing = new this.WidgetBuilder({
            name: 'listing-inline-rating',
            product_id: this.productId,
            container,
        });

        this.waitRenderWidgets.push(listing);

        return this;
    }

    make() {
        // @ts-ignore
        if (!Shoppilot) {
            throw new Error('Aplaut is not loaded yet')
        }

        // @ts-ignore
        const MultiWidget = Shoppilot.require("multi_widget");
        MultiWidget.render(this.waitRenderWidgets);
    }
}
