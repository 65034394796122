import { uniqBy } from 'lodash';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo } from 'react';

import { ProductDetail } from '@api/catalog';

import { Aplaut, ProductWidget } from "@widgets/aplaut/Aplaut";

import BasketButton from '@components/ProductCard/BasketButton';

import { VIEW_OPTIONS } from '@scripts/enums/products';
import { colors, scale, typography } from '@scripts/gds';
import { fromKopecksToRouble, metricsSelectItem } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';

import getAplautApiKey from "@utils/getAplautApiKey";
import CardTopBar from './CardTopBar';
import PictureItem from './PictureItem';
import PriceItem from './PriceItem';

const AttributesItem = dynamic(() => import('./AttributesItem'), { ssr: false });
const Tags = dynamic(() => import('./Tags'), { ssr: false });
const AdditionalBlock = dynamic(() => import('./AdditionalBlock'), { ssr: false });

const ProductCard = ({
                         isSlider,
                         productItem,
                         activeView = VIEW_OPTIONS.DEFAULT,
                     }: {
    isSlider?: boolean;
    productItem: ProductDetail;
    activeView?: string;
}) => {
    const router = useRouter();
    const { md } = useMedia();
    const {
        product_id,
        name,
        images,
        code,
        id,
        nameplates,
        base_price,
        personal_price,
        seller,
        price_unit,
        quantity,
        discount_nameplates,
    } = productItem;

    const buttonId = useMemo(() => nanoid(5), []);

    const linkHandler = useCallback(() => {
        metricsSelectItem(productItem);
    }, [productItem]);

    useEffect(() => {
        Aplaut.create({ storeId: getAplautApiKey() })
            .then(() => {
                new ProductWidget(id.toString())
                    .createListingRating(`#sp-inline-rating-product-${id}`)
                    .make();
            });
    }, []);

    return (
        <div
            onClick={() => {
                router.push(`/product/${code}-${id}`);
            }}
            data-id={id}
            css={{
                height: '100%',
                padding: scale(2),
                width: isSlider ? scale(24) : '100%',
                position: 'relative',
                '#additional-block': {
                    display: 'none',
                },
                '#card-top-bar': {
                    display: activeView === VIEW_OPTIONS.LINES ? 'block' : 'none',
                },
                ':hover': {
                    boxShadow: '0px 4px 16px rgba(61, 123, 160, 0.25)',
                    '#card-top-bar': {
                        display: 'block',
                    },
                    '#additional-block': {
                        display: activeView === VIEW_OPTIONS.LINES ? 'none' : 'block',
                    },
                    '& > div, &:last-child': {
                        opacity: 1,
                    },
                    [`#buy-button-outlineSecondary`]: {
                        backgroundColor: colors.secondaryOnDark,
                        color: colors.white,
                    },
                },
                [md]: {
                    '#card-top-bar': {
                        display: 'block',
                    },
                    border: 'none',
                    borderBottom: activeView === VIEW_OPTIONS.LINES ? `1px solid ${colors.borderMain}` : 'none',
                    padding: scale(1),
                },
                border: activeView === VIEW_OPTIONS.LINES ? `1px solid ${colors.borderMain}` : 'none',
                borderRadius: scale(1),
                display: activeView === VIEW_OPTIONS.LINES ? 'flex' : 'block',
                cursor: 'pointer',
            }}
        >
            <param name="maxQuantity" value={quantity} />

            <PictureItem
                img={images}
                link={`/product/${code}-${id}`}
                title={name || ''}
                activeView={activeView}
                linkHandler={linkHandler}
            />

            <div
                css={{
                    position: 'absolute',
                    top: scale(2),
                    left: scale(2),
                    zIndex: 3,
                }}
            >
                <Tags tags={[...(nameplates || []), ...uniqBy(discount_nameplates || [], 'discount_type')]} />
            </div>

            <div
                onClick={e => e.stopPropagation()}
                id="card-top-bar"
                css={{
                    position: 'absolute',
                    top: scale(2),
                    right: scale(2),
                    zIndex: 3,
                }}
            >
                <CardTopBar product={productItem} />
            </div>

            <div
                css={{
                    display: activeView === VIEW_OPTIONS.LINES ? 'flex' : 'block',
                    flexDirection: activeView === VIEW_OPTIONS.LINES ? 'column' : undefined,
                    width: '100%',

                    ...(activeView === VIEW_OPTIONS.DEFAULT && { paddingBottom: scale(10) }),
                }}
            >
                <PriceItem
                    price={fromKopecksToRouble(personal_price?.price || base_price.price)}
                    old_price={fromKopecksToRouble(personal_price?.cost || base_price.cost)}
                    percentEconomy={personal_price?.discount_percent || base_price.discount_percent}
                    priceUnit={price_unit}
                    activeView={activeView}
                />

                <div
                    css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: activeView === VIEW_OPTIONS.DEFAULT ? 'auto' : scale(29),
                    }}
                >
                    <div id={`sp-inline-rating-product-${id}`}
                         css={{
                             position: 'absolute',
                             bottom: scale(9),
                             left: scale(2),
                             right: scale(2),
                             display: 'flex',
                             minHeight: scale(3),
                         }}
                    />
                    <div
                        css={{
                            marginBottom: scale(2),
                            marginRight: activeView === VIEW_OPTIONS.LINES ? scale(13) : 0,
                            height: activeView === VIEW_OPTIONS.LINES ? 'auto' : scale(7),
                            [md]: {
                                marginRight: activeView === VIEW_OPTIONS.LINES ? scale(7) : 0,
                            },
                        }}
                    >
                        <Link legacyBehavior href={`/product/${code}-${id}`} passHref prefetch={false}>
                            <a
                                aria-label={name}
                                onClick={linkHandler}
                                css={{
                                    overflow: 'hidden',
                                    width: '100%',
                                    WebkitBoxOrient: 'vertical',
                                    display: '-webkit-box',
                                    textOverflow: 'ellipsis',
                                    'WebkitLineClamp': 3,

                                    ...(activeView === VIEW_OPTIONS.LINES && {
                                        ...typography('bodyLgMedium'),
                                    }),
                                }}
                            >
                                {name}
                            </a>
                        </Link>

                        {activeView === VIEW_OPTIONS.LINES && (
                            <div
                                css={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <p
                                    css={{
                                        marginTop: scale(1),
                                        ...typography('bodyXSRegular'),
                                        color: colors.textSecondGrey,
                                        overflow: 'hidden',
                                        width: '100%',
                                        WebkitBoxOrient: 'vertical',
                                        display: '-webkit-box',
                                        textOverflow: 'ellipsis',
                                        'WebkitLineClamp': 7,
                                        [md]: {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    <AttributesItem productItem={productItem} />
                                </p>
                            </div>
                        )}
                    </div>
                    <div
                        css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: scale(3) }}
                    >
                        {activeView === VIEW_OPTIONS.LINES && (
                            <div
                                css={{
                                    display: 'flex',
                                    width: `calc(100% - ${scale(30)}px)`,
                                    [md]: {
                                        display: 'none',
                                    },
                                }}
                            >
                                <div
                                    css={{
                                        ...typography('bodyXSRegular'),
                                        color: colors.textSecondGrey,
                                        marginRight: scale(2),
                                    }}
                                >
                                    Продавец:
                                </div>
                                <div
                                    css={{
                                        textDecoration: 'underline',
                                        ...typography('bodyXSRegular'),
                                        WebkitBoxOrient: 'vertical',
                                        display: '-webkit-box',
                                        textOverflow: 'ellipsis',
                                        WebkitLineClamp: 3,
                                        overflow: 'hidden',
                                    }}
                                >
                                    {seller?.name}
                                </div>
                            </div>
                        )}

                        <div
                            onClick={e => e.stopPropagation()}
                            css={{
                                ...(activeView === VIEW_OPTIONS.DEFAULT && {
                                    position: 'absolute',
                                    bottom: scale(2),
                                    left: scale(2),
                                    right: scale(2),
                                }),
                            }}
                        >
                            <BasketButton productItem={productItem} activeView={activeView} buttonId={buttonId} />
                        </div>
                    </div>
                </div>
            </div>

            {!isSlider && <AdditionalBlock sellerName={seller?.name} />}
        </div>
    );
};

export default ProductCard;

